<!--des:种子企业详情-->
<script>


  export default {
    data() {
      return {
        DetailInfo: {}, // 详情数据
        id: '',
        imgUrl: []
      };
    },
    created() {
      this.id = this.$route.query.id;
      this.getDetailInfo(this.$route.query.id);
    },
    methods: {
      async getDetailInfo(id) { // 详情数据请求
        console.log(id);
        const api = this.$fetchApi.supplementaryDetail.api;
        const res = await this.$fetchData.fetchPost({}, api + id, 'json');
        if (res.code === '200') {
          this.DetailInfo = res.result;
          this.imgUrl = (this.DetailInfo.pictureUrl).split(',');
        }
      }
    }
  };

</script>

<template>
  <div class="container">
    <header>
      <div class="h2">{{DetailInfo.firmName}}</div>
      <div class="content DetainSize">
        <div class="text_content"><span>联系方式：</span>
          <p>{{DetailInfo.contactPhone}}</p></div>
        <div class="text_content"><span>公司网址：</span>
          <p>{{DetailInfo.firmUrl}}</p></div>
        <div class="text_content"><span>公司地址：</span>
          <p>{{DetailInfo.firmSite}}</p></div>
        <div class="text_introduction"><span>公司简介：</span>
          <p>
            {{DetailInfo.firmIntro}}
          </p></div>
      </div>
    </header>
    <!--    产品介绍-->
    <main class="DetainSize">
      <div class="h2">产品介绍</div>
      <p>{{DetailInfo.presentation}}</p>
      <div class="showImg">
        <img :src="item" alt="" v-for="(item,index) in imgUrl" :key="index">
      </div>
    </main>
  </div>
</template>

<style scoped lang="less">
  header {
    width: 1180px;
    background: rgba(255, 255, 255, 1);
    margin: 20px auto 20px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    padding: 23px 30px 30px 19px;
  }

  .h2 {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    margin-bottom: 23px;
  }

  .DetainSize {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(84, 84, 84, 1);
    line-height: 30px;
  }

  .text_content {
    ont-size: 16px;
    font-family: Microsoft YaHei;
    color: rgba(84, 84, 84, 1);
    display: flex;

    span {
      width: 82px;
    }
  }

  .text_introduction {
    display: flex;

    p {
      width: 100%;
    }

    span {
      width: 90px;
    }
  }

  main {
    width: 1180px;
    background: rgba(255, 255, 255, 1);
    margin: 20px auto 20px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    padding: 23px 30px 30px 19px;

    img {
      width: 340px;
      height: 220px;
    }

    .showImg {
      margin-top: 40px;
      display: flex;
      justify-content: space-around;
    }
  }
</style>
